import { useEffect, useState } from 'react'
import ApplyVantagePointCommand from 'render-streaming-client/build/command/ApplyVantagePointCommand'
import GetCurrentVantagePoint from 'render-streaming-client/build/command/GetCurrentVantagePointCommand'

import {DropdownThumbSelector, VideoCamIcon} from '~elements'
import { usePreviewStore } from '~store'
import { ICurrentVantagePointData, IVPCategory, IVPThumbnailDetails } from '~interfaces'
import { getSelectedVP } from '~utils'

export const VantagePoints = () => {
  const {
    renderingManager,
    allVantagePoints,
    selectedVantagePoint,
    setSelectedVantagePoint,
    selectedVPCategory,
    setSelectedVPCategory,
    allVPCategories
  } = usePreviewStore()

  const getCategoryIdx = (newCategory: IVPCategory | null): number => {
    if (newCategory) {
      return allVPCategories.findIndex(category => newCategory.id === category.id)
    }
    return -1
  }

  //Tracks the user's movement between tabs in the dropdown menu
  const [currentVPCategory, setCurrentVPCategory] = useState<IVPCategory | null>(selectedVPCategory)

  useEffect(() => {
    return () => {
      setSelectedVantagePoint(undefined)
    }
  }, [])

  useEffect(() => {
    //We only fetch the current vantage point when necessary
    const fetchData = async () => {
      if (renderingManager && allVPCategories?.length && !selectedVantagePoint) {
        await getSelectedVantagePoint()
      }
    }
    fetchData().catch(error => console.error(error))
  }, [renderingManager, allVPCategories])

  const getSelectedVantagePoint = async () => {
    const getCurrent = new GetCurrentVantagePoint()
    renderingManager?.onCommandResponse(getCurrent, data => {
      try {
        const parsedResponse: ICurrentVantagePointData = JSON.parse(data)
        const selected = getSelectedVP(parsedResponse.Payload.vantagePointInfo.scenarioId, allVantagePoints)
        const selectedCategory = allVPCategories.find(category => category.id === selected?.category_id)
        if (selected && selectedCategory) {
          setSelectedVantagePoint(selected)
          setSelectedVPCategory(selectedCategory)
          setCurrentVPCategory(selectedCategory)
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }

        throw new Error('Unknown error in getting current vantage point.')
      }
    })
    renderingManager?.executeCommand(getCurrent)
  }

  const applyVantagePoint = (vantagePoint: IVPThumbnailDetails) => {
    if (!renderingManager) {
      return
    }

    try {
      const applyVantagePoint = new ApplyVantagePointCommand(vantagePoint.unreal_guid)

      // Please use for debugging purposes.
      // renderingManager.onCommandResponse(applyVantagePoint, data => {
      //   console.log(data)
      // })

      renderingManager.executeCommand(applyVantagePoint)
      setSelectedVantagePoint(vantagePoint)

      if (currentVPCategory) {
        setSelectedVPCategory(currentVPCategory)
      }

      console.log(`Attempt to apply vantage point ${vantagePoint.unreal_guid}`)
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }

      throw new Error('Unknown error in applying vantage points.')
    }
  }

  const onOutsideClick = () => {
    if (currentVPCategory?.category_name !== selectedVPCategory?.category_name) {
      setCurrentVPCategory(selectedVPCategory)
    }
  }

  const applyTabChange = (newCategoryIdx: number) => {
    const newCategory = allVPCategories[newCategoryIdx]
    setCurrentVPCategory(newCategory)
  }

  return renderingManager && allVPCategories?.length && selectedVantagePoint ? (
    <DropdownThumbSelector
      onChange={applyVantagePoint}
      onOutsideClick={onOutsideClick}
      groups={currentVPCategory ? currentVPCategory.groups : []}
      selectedItem={selectedVantagePoint}
      Icon={VideoCamIcon}
      type={'VANTAGE-POINT'}
      navTabDetails={{
        navigationTabLabels: [...allVPCategories.map(category => category.category_name)],
        handleTabChange: applyTabChange,
        currentTabIdx: currentVPCategory ? getCategoryIdx(currentVPCategory) : -1
      }}
    />
  ) : null
}
