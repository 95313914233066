import React from 'react'
import cx from 'classnames'

import {HeadingProps} from '~interfaces'

import styles from './index.module.scss'

export const Heading = ({
                   children,
                   level,
                   levelDisplay,
                   isEyebrow = false,
                   isUppercase = false,
                   justify = 'left',
                   title = '',
                   margin,
                   className,
                   ...restStyle
                 }: HeadingProps) => {
  const classNames = cx([
    styles[`heading`],
    styles[`level-${levelDisplay ? levelDisplay : level}`],
    styles[justify === 'center' ? `justify-${justify}` : ''],
    styles[isEyebrow ? 'eyebrow' : ''],
    styles[isUppercase ? 'to-uppercase' : ''],
    className
  ])
  const HeadingTag = `h${level}`

  return (
    //@ts-ignore
    <HeadingTag className={classNames} style={{margin, ...restStyle}}
                     dangerouslySetInnerHTML={{__html: children ? children : title}}/>
  )
}
