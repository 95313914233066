import {IAuthenticate} from "~interfaces";

export const authenticate = async ({ username, password, rememberMe }: IAuthenticate) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/login`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        rememberMe
      }),
      credentials: 'include'
    })

    if (result.status == 200 || result.status === 401) {
      const json = await result.json()
      return await { ...json, statusCode: result.status }
    } else {
      throw new Error()
    }
  } catch (e) {
    throw new Error('Unable to login user.')
  }
}