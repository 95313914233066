import {CognitoIdentityProviderClient, ForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import {CLIENT_ID} from "~constants";
import {getSecretHash} from "~utils";
import {IBasePassword, IInitPasswordOutput} from "~interfaces";

export const InitPasswordRequest = ({ Username }: IBasePassword): Promise<IInitPasswordOutput> => {

  const client = new CognitoIdentityProviderClient({ region: 'us-east-1' })

  return new Promise(resolve => {
    const secretHash = getSecretHash(Username)

    const input = {
      ClientId: CLIENT_ID,
      SecretHash: secretHash,
      Username
    }

    const command = new ForgotPasswordCommand(input)

    client.send(command, (err, data) => {
      if (data) {
        resolve({ message: 'CodeSent', statusCode: 200, status: 'Success', name: null })
      }

      if (err) {
        resolve({ message: '', statusCode: 400, status: 'Error', name: err.name })
      }
    })
  })
}