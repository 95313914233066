'use client'

import { useState, useRef } from 'react'
import { usePathname } from 'next/navigation'
import cx from 'classnames'
import { Container } from 'react-grid-system'

import { BrandLogo, ExitPreviewButton, FileName, LoggedInInitials, NavMenu, TimeOfDay, VantagePoints, NavShareVideo } from '~elements'

import { IHeaderBlock } from '~interfaces'

import styles from './index.module.scss'
import { useOutsideClick, useWindowSize } from '~hooks'

export const Header = ({ ctaSection, isPreview = false, logo, navMenuPrimary, simple = false, videoPlayer = false }: IHeaderBlock) => {
  const [navDrawerIsOpen, handleNavDrawerToggle] = useState(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<', () => handleNavDrawerToggle(false))
  const isMobileBreakpoint = isBelowBreakpointMd
  const [openParentNavId, setOpenParentNavId] = useState('')
  const [subNavChildPage, setSubNavChildPage] = useState(true)
  const ref = useRef(null)
  const pathName = usePathname()

  const closeParentNavClickHandler = () => {
    setOpenParentNavId('')
  }

  useOutsideClick(ref, () => {
    closeParentNavClickHandler()
    setSubNavChildPage(false)
  })

  const closeDrawerHandler = () => {
    if (navDrawerIsOpen) handleNavDrawerToggle(false)
  }

  const NavDesktop = () => {
    const colClassNames = [styles['header-global__column'], styles[videoPlayer ? 'header-global__video-player' : '']]

    return (
      <>
        <Container className={'container container--width-100'}>
          <div className={cx(colClassNames)}>
            {videoPlayer ? <ExitPreviewButton /> : null}
            <BrandLogo isPreview={isPreview} logo={logo} useAltLogo={true} />
            {videoPlayer ? (
              <>
                <FileName />
                <div className={styles['button-container']}>
                  <VantagePoints />
                  <TimeOfDay />
                  {process.env.NEXT_PUBLIC_HIDE_SHARE ? null : <NavShareVideo />}
                </div>
              </>
            ) : null}
            <div className={styles['nav-container']}>
              {navMenuPrimary?.length ? (
                <nav>
                  <NavMenu
                    navMenuPrimary={navMenuPrimary}
                    navDrawerIsOpen={navDrawerIsOpen}
                    subnavChildPage={subNavChildPage}
                    handleNavDrawerToggle={handleNavDrawerToggle}
                    setOpenParentNavId={setOpenParentNavId}
                    setSubnavChildPage={setSubNavChildPage}
                    openParentNavId={openParentNavId}
                  />
                </nav>
              ) : null}
              <LoggedInInitials />
            </div>
          </div>
        </Container>
      </>
    )
  }

  const NavMobile = () => {
    const classNames = [styles['header-global__column'], styles['header-global__mobile'], styles[videoPlayer ? 'header-global__video-player' : '']]

    return (
      <>
        <div className={cx(classNames)}>
          {videoPlayer ? <ExitPreviewButton /> : null}
          <BrandLogo isPreview={isPreview} logo={logo} useAltLogo={true} />
          {videoPlayer ? (
            <>
              <FileName />
              <div className={styles['button-container']}>
                <VantagePoints />
                <TimeOfDay />
                {process.env.NEXT_PUBLIC_HIDE_SHARE ? null : <NavShareVideo />}
              </div>
            </>
          ) : null}
          <div className={styles['nav-container']}>
            <LoggedInInitials />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <header
        ref={ref}
        onClick={closeDrawerHandler}
        className={cx([
          styles['header-global'],
          styles[isMobileBreakpoint ? 'header-global--mobile' : 'header-global--desktop'],
          styles[navDrawerIsOpen && isMobileBreakpoint ? 'drawer-open' : ''],
          styles[ctaSection && ctaSection.length === 2 ? 'header-global--has-two-cta-btns' : ''],
          styles[simple ? 'header-global__simple' : ''],
          styles[pathName === '/' ? 'preview-page' : ''],
          styles[videoPlayer ? 'header-global__video-player' : '']
        ])}
      >
        {isMobileBreakpoint ? NavMobile() : NavDesktop()}
      </header>
    </>
  )
}
