import { IShareFileProps } from '~interfaces'
import styles from '../ListView/index.module.scss'
import { SimpleShareButton } from '~elements'

export const ShareFile = ({ record, closeDropdownMenu, displayError, openManageRecipientsModal }: IShareFileProps) => {
  const handleOpenShareModal: React.MouseEventHandler = () => {
    if (record.title) {
      try {
        openManageRecipientsModal()
        closeDropdownMenu()
      } catch (error) {
        console.error(error)
        displayError(`Failed to open manage recipients modal for file ${record.title}`)
        closeDropdownMenu()
      }
    } else {
      closeDropdownMenu()
    }
  }

  return <SimpleShareButton displayText={'share'} handleClick={handleOpenShareModal} styles={styles} />
}
