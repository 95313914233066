import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { CASING, NAV_TABS_TYPES, NavigationTabs } from '@msgtechnology/sphere'

import { useOutsideClick } from '~hooks'

import { IVPGroup, IVPThumbnailDetails, IToDThumbnailDetails } from '~interfaces'

import styles from './index.module.scss'
import { DownChevronIcon } from '../Icons'
import { DropdownThumbnail } from '../DropdownThumbnail'

interface DropdownThumbSelectorProps {
  //eslint-disable-next-line
  onChange: Function
  onOutsideClick?: () => void
  groups: Array<IVPGroup>
  navTabDetails?: {
    navigationTabLabels: Array<string>
    currentTabIdx: number
    handleTabChange: (newCategoryIdx: number) => void
  }
  selectedItem?: IToDThumbnailDetails | IVPThumbnailDetails
  Icon: () => JSX.Element
  type: 'VANTAGE-POINT' | 'TIME-OF-DAY'
}

export const DropdownThumbSelector = ({ onChange, onOutsideClick, groups, navTabDetails, selectedItem, Icon, type }: DropdownThumbSelectorProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(navTabDetails?.currentTabIdx ? navTabDetails?.currentTabIdx : -1)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const toggleDropdown = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (navTabDetails?.currentTabIdx) {
      setActiveTabIndex(navTabDetails?.currentTabIdx)
    }
  }, [navTabDetails?.currentTabIdx])

  const handleItemClick = (item: IToDThumbnailDetails | IVPThumbnailDetails) => {
    setIsOpen(false)
    onChange(item)
  }

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
    if (onOutsideClick) {
      onOutsideClick()
    }
  })

  const handleChangeTab = (newTabIdx: number) => {
    setActiveTabIndex(newTabIdx)
    if (navTabDetails) {
      navTabDetails.handleTabChange(newTabIdx)
    }
  }

  const menuClassNames = cx([styles['dropdown-menu'], styles[isOpen ? '' : 'hidden'], styles[type === 'TIME-OF-DAY' ? 'time-of-day' : '']])

  const displayGroup = (group: IVPGroup) => {
    return (
      <React.Fragment key={group.id}>
        {group.group_name !== 'default' ? <li role="none">{group.group_name}</li> : null}
        <div className={styles['group-thumbnails']}>
          {group.vantage_points.map(item => (
            <DropdownThumbnail key={item.unreal_guid} item={item} selectedItem={selectedItem} handleItemClick={handleItemClick} />
          ))}
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={styles['dropdown-container']} ref={dropdownRef} data-testid="dropdown-thumb-selector">
      <button onClick={toggleDropdown}>
        <Icon />
        <div className={styles['item-display-name']}>{selectedItem?.name}</div>
        <DownChevronIcon />
      </button>
      {groups.length ? (
        <div className={menuClassNames}>
          {navTabDetails && activeTabIndex > -1 ? (
            <NavigationTabs
              activeIndex={activeTabIndex}
              setActiveIndex={handleChangeTab}
              labels={[...navTabDetails?.navigationTabLabels]}
              casing={CASING.UPPER}
              type={NAV_TABS_TYPES.DROPDOWN}
            />
          ) : null}
          <ul role="menu">{groups ? groups.map(group => displayGroup(group)) : null}</ul>
        </div>
      ) : null}
    </div>
  )
}
