import {FORGOT_PASSWORD_ACTIONS} from "~enums";
import {Dispatch, SyntheticEvent} from "react";
import {IForgotPasswordReducerAction, ILoginReducerAction, IPreviewForgotPasswordForm} from "~interfaces";
import {InitPasswordRequest} from "~services";

export const handleInitPasswordVerification = async (event: SyntheticEvent<IPreviewForgotPasswordForm>, dispatch: Dispatch<ILoginReducerAction | IForgotPasswordReducerAction>) => {
  const {email: emailElement} = event.currentTarget.elements
  const email = emailElement.value

  try {
    const result = await InitPasswordRequest({Username: email})

    if (result.status === 'Success') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.INIT,
        payload: {
          email
        }
      })
    } else if (result.status === 'Error' && result.name !== 'LimitExceededException') {
      throw new Error('Failed to send verification code')
    } else if (result.status === 'Error' && result.name === 'LimitExceededException') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.INIT,
        payload: {
          email
        }
      })
    }
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      payload: {
        cognitoError: error
      }
    })
  }
}