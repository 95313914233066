'use client'

import { ComponentType, useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { getCognitoUserSession } from 'utils/user/getCognitoUserSession'
import { PREVIEW_PATH } from '~constants'
import { IFetchPageBySlug } from '~interfaces'
import { PreviewViewerForm } from '~components'

interface IsAuthenticatedProps extends IFetchPageBySlug {}

const PREVIEW_LOGIN_PATH = `${PREVIEW_PATH}login`
const PREVIEW_VIDEO_PATH = `${PREVIEW_PATH}video`

export function isAuthenticated<T extends IsAuthenticatedProps = IsAuthenticatedProps>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'
  const ComponentIsAuthenticated = (props: Pick<T, keyof IsAuthenticatedProps>) => {
    const router = useRouter()
    const pathName = usePathname()
    const searchParams = useSearchParams()
    const [redirectTo, setRedirectTo] = useState<null | string>(null)
    const [isReady, setIsReady] = useState(false)
    const [showEmailModal, setShowEmailModal] = useState(false)

    const { checkAuthentication } = props

    useEffect(() => {
      ;(async () => {
        if (checkAuthentication) {
          const currentCognitoUser = await getCognitoUserSession()
          const isAuthenticated = currentCognitoUser !== null

          // If unauthenticated, handle /video logic
          if (!isAuthenticated) {
            if (pathName === PREVIEW_VIDEO_PATH && searchParams.get('id') && !process.env.NEXT_PUBLIC_HIDE_SHARE) {
              // Show email modal for unauthenticated users trying to access /video
              setShowEmailModal(true)
            } else if (pathName !== PREVIEW_LOGIN_PATH) {
              // Set redirect URL for other paths
              const id = searchParams.get('id')
              const redirectUrl = id ? `${PREVIEW_LOGIN_PATH}?next=${encodeURIComponent(`${PREVIEW_VIDEO_PATH}?id=${id}`)}` : PREVIEW_LOGIN_PATH
              setRedirectTo(redirectUrl)
            } else {
              setIsReady(true)
            }
          } else {
            // If authenticated but on /login, set redirect to preview path
            if (pathName === PREVIEW_LOGIN_PATH) {
              setRedirectTo(PREVIEW_PATH)
            } else {
              setIsReady(true)
            }
          }
        } else if (!checkAuthentication) {
          setIsReady(true)
        }
      })()
    }, [pathName])

    useEffect(() => {
      if (redirectTo && pathName) {
        router.push(redirectTo)
        setIsReady(true)
      }

      return () => {
        setRedirectTo(null)
        setIsReady(false)
      }
    }, [redirectTo, pathName])

    if (showEmailModal) {
      // Render the viewer modal component
      return <PreviewViewerForm />
    }

    if (isReady) {
      return <WrappedComponent isAuthenticated={isAuthenticated} {...(props as T)} />
    }

    // Most likely won't get here, but just for good measure.
    return null
  }

  ComponentIsAuthenticated.displayName = `isAuthenticated(${displayName})`

  return ComponentIsAuthenticated
}
