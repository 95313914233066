import {Dashboard, Header, LoginForm, RenderClient} from "~components";
import {IBlock, IDashboardBlock, IFormBlock, IHeaderBlock} from "~interfaces";

export const getDynamicComponents = (data: { blocks: Array<IHeaderBlock | IFormBlock | IBlock | IDashboardBlock>}) => {
  return data.blocks.map(block => {
    switch (block.componentName) {
      case 'Header': {
        if ("navMenuPrimary" in block) {
          return (
            // @ts-ignore
            <Header key={block.id} {...block}>
              {block.title}
            </Header>
          )
        }
        console.error('No block was found in data.')
        break;
      }
      case 'Dashboard': {
        if ("paragraph" in block) {
          return (
            //@ts-ignore
            <Dashboard key={block.id} {...block} />
          )
        }
        break;
      }
      case 'RenderClient': {
        return (
          <RenderClient key={block.id} {...block} />
        )
      }
      case 'LoginForm': {
        if ("backToSignIn" in block) {
          return (<LoginForm key={block.id} {...block} />)
        }
      }
    }
  })
}