interface CircleProgressBarProps {
  progress: number
  baseColor: string
  progressColor: string
}

export const CircleProgressBar = ({ progress, baseColor, progressColor }: CircleProgressBarProps) => {
  const radius = 45
  const circumference = 2 * Math.PI * radius
  const progressOffset = ((100 - progress) / 100) * circumference

  return (
    <svg width="32" height="32" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r={radius} stroke={baseColor} strokeWidth="10" fill="none" />
      <circle
        cx="50"
        cy="50"
        r={radius}
        stroke={progressColor}
        strokeWidth="10"
        fill="none"
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        style={{ transition: 'stroke-dashoffset 0.35s' }}
        transform="rotate(-90 50 50)"
      />
    </svg>
  )
}