import { usePreviewStore } from 'store'
import { useState } from 'react'
import styles from 'components/Header/index.module.scss'
import { SimpleShareButton, ManageRecipientsModal } from '~elements'

export const NavShareVideo = () => {
  const { selectedVideo } = usePreviewStore()
  const [manageRecipientsModalOpen, setManageRecipientsModalOpen] = useState<boolean>(false)

  const handleShare: React.MouseEventHandler = () => {
    try {
      //Share
      setManageRecipientsModalOpen(true)
      console.log(`Sharing file ${selectedVideo.fileName}`)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <SimpleShareButton displayText={'share'} handleClick={handleShare} styles={styles} />
      <ManageRecipientsModal
        fileName={selectedVideo.fileName}
        isOpen={manageRecipientsModalOpen}
        handleModalClose={() => setManageRecipientsModalOpen(false)}
      />
    </>
  )
}
