interface IVideoTitle {
  title: string
}

export const VideoTitle = ({ title }: IVideoTitle) => {
  const [experienceId, ...rest] = title.split('_')
  const [versionWithFileExt, ...remaining] = [...rest.reverse()]
  const DRPGG = remaining.reverse().join('_')
  return (
    <>
      <span data-testid="experience-id">{experienceId}</span>
      <span data-testid="DRPGG">{`_${DRPGG}_`}</span>
      <span data-testid="versionWithFileExt">{versionWithFileExt}</span>
    </>
  )
}
