import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { LinkIcon } from '~elements'

interface CopyAndShareButtonProps {
  displayText: string
  shareTitle: string
  tooltipText?: string
  url?: string
  styles: {
    readonly [key: string]: string
  }
  Icon?: () => JSX.Element
  useNavigatorShare: boolean //If false, simply copy link to clipboard
}

export const CopyAndShareButton = ({
  displayText,
  shareTitle,
  tooltipText,
  url,
  styles,
  Icon = LinkIcon,
  useNavigatorShare = true
}: CopyAndShareButtonProps) => {
  const [canOpenShare, setCanOpenShare] = useState(false)

  const timer = useRef<NodeJS.Timeout>()
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)

  const shareData = {
    url: url || new URL(window.location.href).toString(),
    title: shareTitle
  }

  useEffect(() => {
    if (navigator.canShare && navigator.canShare(shareData)) {
      setCanOpenShare(true)
    }
  }, [])

  const handleShare = async () => {
    if (timer.current) {
      clearTimeout(timer.current)
      setIsLinkCopied(false)
    }

    if (canOpenShare && useNavigatorShare) {
      try {
        await navigator.share(shareData)
      } catch (error) {
        console.error('Unable to share: ', error)
      }
    } else {
      navigator.clipboard.writeText(shareData.url)
      setIsLinkCopied(true)

      timer.current = setTimeout(() => {
        setIsLinkCopied(false)
      }, 3000)
    }
  }

  useEffect(() => {
    // Clear timeout on component unmount
    return () => clearTimeout(timer.current)
  }, [])

  return (
    <button
      onClick={() => {
        handleShare()
      }}
      className={styles['copy-link-button']}
      data-testid={'copy-link-button'}
    >
      <div className={styles['display-text']}>
        {tooltipText && <span className={cx(styles['tooltip-text'], isLinkCopied ? styles['show'] : '')}>{tooltipText}</span>}
        {Icon && <Icon />}
        <span className={styles['item-display-name']}>{displayText}</span>
      </div>
    </button>
  )
}
