import {Dispatch, SyntheticEvent} from "react";
import {IPreviewLoginForm} from "~interfaces";
import {LOCAL_STORAGE, LOGIN_ACTIONS} from "~enums";
import {authenticate} from "~services";
import {ReadonlyURLSearchParams} from "next/navigation";

export const handleLogin = async (event: SyntheticEvent<IPreviewLoginForm>, dispatch: Dispatch<any>, searchParams: ReadonlyURLSearchParams) => {
  const { email, password, rememberMe } = event.currentTarget.elements

  try {
    const authRes = await authenticate({
      username: email.value,
      password: password.value,
      rememberMe: rememberMe.checked
    })

    if (authRes.statusCode == 401 && authRes.session_code) {
      dispatch({
        type: LOGIN_ACTIONS.SET_NEW,
        payload: {
          email: email.value,
          session_code: authRes.session_code,
          userAttributes: authRes.userAttributes
        }
      })
    } else {
      // Set a user's token based on the authRes value
      localStorage.setItem(
        LOCAL_STORAGE.USER_KEY,
        JSON.stringify({
          id: authRes.id,
          refresh: authRes.refresh
        })
      )

      const rememberMeItem = localStorage.getItem(LOCAL_STORAGE.EXO_REMEMBER_ME)

      //If user email was previously stored and the user unchecked rememberMe
      if (rememberMeItem && !rememberMe.checked) {
        //Remove the email from local storage
        localStorage.removeItem(LOCAL_STORAGE.EXO_REMEMBER_ME)
      }
      //If the user checked rememberMe, save the rememberMe item in local storage
      if (rememberMe.checked) {
        const thirtyDayExpiry = Date.now() + 1000 * 60 * 60 * 24 * 30

        const rememberMeItem = {
          email: email.value,
          expiry: thirtyDayExpiry
        }

        localStorage.setItem(LOCAL_STORAGE.EXO_REMEMBER_ME, JSON.stringify(rememberMeItem))
      }

      dispatch({
        type: LOGIN_ACTIONS.SUBMIT,
        payload: {
          email: email.value,
          password: password.value,
          rememberMe: rememberMe.checked
        }
      })

      return searchParams.get('next') || '/'
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}