import RenderStreamingManager from 'render-streaming-client/build/RenderStreamingManager'
import { create } from 'zustand'
import {IToDThumbnailDetails, IVPCategory, IVPThumbnailDetails} from "~interfaces";

interface IPreviewVideo {
  url: string
  fileName: string
}

export interface IOption {
  scenarioId: string
  displayName: string
  doesExist?: boolean
}

interface IPreviewState {
  renderingManager: null | RenderStreamingManager
  selectedVideo: IPreviewVideo
  //Vantage Points
  allVantagePoints: Array<IVPThumbnailDetails>
  selectedVantagePoint: IVPThumbnailDetails | undefined
  allVPCategories: Array<IVPCategory>
  selectedVPCategory: IVPCategory | null
  //Times of Day
  allTimesOfDay: Array<IToDThumbnailDetails>
  selectedTimeOfDay: IToDThumbnailDetails | undefined
  displayExitModal: boolean
  resetRenderingManager: () => void
  setRenderingManager: (manager: RenderStreamingManager | null) => void
  setSelectedVideo: (video: IPreviewVideo) => void
  //Vantage Points
  setAllVantagePoints: (vantagePoints: Array<IVPThumbnailDetails>) => void
  setSelectedVantagePoint: (vantagePoint: IVPThumbnailDetails | undefined) => void
  setAllVPCategories: (categories: Array<IVPCategory>) => void
  setSelectedVPCategory: (category: IVPCategory) => void
  //Times of Day
  setAllTimesOfDay: (allTimesOfDay: Array<IToDThumbnailDetails>) => void
  setSelectedTimeOfDay: (timeOfDay: IToDThumbnailDetails | undefined) => void
  setDisplayExitModal: (displayExitModal: boolean) => void
}

const VIDEO_INITIAL_STATE = {
  url: '',
  fileName: ''
}

export const usePreviewStore = create<IPreviewState>(set => ({
  renderingManager: null,
  selectedVideo: VIDEO_INITIAL_STATE,
  allVantagePoints: [],
  selectedVantagePoint: undefined,
  allVPCategories: [],
  selectedVPCategory: null,
  allTimesOfDay: [],
  selectedTimeOfDay: undefined,
  displayExitModal: false,
  resetRenderingManager: () => set({ renderingManager: null }),
  setRenderingManager: manager => set({ renderingManager: manager }),
  setSelectedVideo: video => set({ selectedVideo: video }),
  setAllVantagePoints: vantagePoints => set({ allVantagePoints: [...vantagePoints] }),
  setSelectedVantagePoint: vantagePoint => set({ selectedVantagePoint: vantagePoint }),
  setAllVPCategories: categories => set({ allVPCategories: [...categories] }),
  setSelectedVPCategory: category => set({ selectedVPCategory: category }),
  setAllTimesOfDay: allTimesOfDay => set({ allTimesOfDay: [...allTimesOfDay] }),
  setSelectedTimeOfDay: timeOfDay => set({ selectedTimeOfDay: timeOfDay }),
  setDisplayExitModal: displayExitModal => set({ displayExitModal })
}))
