import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './index.module.scss'
import { ModalProps } from '~interfaces'
import { disableBodyScroll } from 'utils'
import cx from 'classnames'
import { CloseIcon } from '~elements'

export const Modal = ({
  isOpen,
  handleModalClose,
  children,
  closeOnOutsideClick = true,
  contentWidth,
  isRecipientsModal,
  showCloseButton = true
}: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event
      if (key === 'Escape' && isOpen && handleModalClose) {
        handleModalClose()
      }
    },
    [isOpen]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }
  const classNames = cx(styles['modal-content'], styles[`modal-content--${contentWidth}`], {
    [styles['recipients-modal-content']]: isRecipientsModal
  })

  const modalContent = isOpen ? (
    <div className={styles.modal}>
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={classNames}>
          {showCloseButton && (
            <button className={styles['icon-close-container']} onClick={handleModalClose}>
              <CloseIcon />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById('root-modal') as Element)
  } else {
    return null
  }
}
