import { Modal } from '~elements'
import { RecipientsModalHeader } from './RecipientsModalHeader'
import { RecipientsModalBody } from './RecipientsModalBody'
import { RecipientsModalFooter } from './RecipientsModalFooter'
import styles from './index.module.scss'

interface RecipientsModalProps {
  fileName: string
  isOpen: boolean
  handleModalClose: () => void
  shareUrl?: string
}

export const ManageRecipientsModal = ({ fileName, isOpen, handleModalClose, shareUrl }: RecipientsModalProps) => {
  return (
    <Modal isOpen={isOpen} isRecipientsModal={true} contentWidth={'narrow'} handleModalClose={handleModalClose}>
      <div className={styles['modal-content-container']}>
        <RecipientsModalHeader fileName={fileName} />
        <RecipientsModalBody />
        <RecipientsModalFooter fileName={fileName} shareUrl={shareUrl} />
      </div>
    </Modal>
  )
}
