import breakpoints from '../../styles/variables.breakpoints.module.scss'

/**
 * Returns window size and whether size is above or below a specified breakpoint
 * if function is done calculating user's screen size on every window resize event
 * @param {string} breakpoint
 * @param {string} operator
 * @param {callback} callback - optional parameter that runs a function if needed
 * @returns {{number, boolean, boolean}} - object that returns window size, whether or not it's on or below breakpoint,
 * and if function is done calculating user's screen size
 */
import {useEffect, useState} from "react";

export const useWindowSize = (
  breakpoint: string | 'xs' | 'sm' | 'md' | 'lg',
  operator: string | '>' | '>=' | '<' | '<=',
  callback?: () => void
): { windowSize: number; isBreakpoint: boolean; windowSizeCalculation: boolean } => {
  const [windowSize, setWindowSize] = useState<number>(typeof window !== 'undefined' ? window.innerWidth : 0)
  const [isBreakpoint, setIsLowerThanSpecifiedBreakpoint] = useState<boolean>(false)
  // prevents flicking during calculating user's window size
  const [windowSizeCalculation, setWindowSizeCalculation] = useState<boolean>(false)
  const breakpointMapped: number = Number(breakpoints[breakpoint])

  const operators = {
    '<': (a: number, b: number) => a < b,
    '<=': (a: number, b: number) => a <= b,
    '>': (a: number, b: number) => a > b,
    '>=': (a: number, b: number) => a >= b
  }

  useEffect(() => {
    //@ts-ignore
    const breakpointBoolean = operators[operator](windowSize, breakpointMapped)
    if (!breakpointBoolean && callback) {
      // optional callback used in Header component
      callback()
    }
    setIsLowerThanSpecifiedBreakpoint(breakpointBoolean)
  }, [breakpointMapped, windowSize])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize(window.innerWidth)
      }
      handleResize()
      setWindowSizeCalculation(true)
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [windowSize])

  return { windowSize, isBreakpoint, windowSizeCalculation }
}