import { LOCAL_STORAGE } from '~enums'

interface IDeleteFile {
  videoId: string
}

export const DeleteFile = async ({ videoId }: IDeleteFile) => {
  const GENERIC_ERROR_MESSAGE = 'Unable to delete file.'

  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData) {
      throw new Error('Session data is missing.')
    }

    const { id } = JSON.parse(userSessionData)
    headers.append('Authorization', `Bearer ${id}`)

    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/delete`, {
      method: 'DELETE',
      body: JSON.stringify({
        id: videoId
      }),
      headers
    })

    if (result.status !== 200) {
      throw new Error(GENERIC_ERROR_MESSAGE)
    }

    return await result.json()
  } catch (e) {
    let message = GENERIC_ERROR_MESSAGE

    if (e instanceof Error) {
      message = e.message
    }

    throw new Error(message)
  }
}
