'use client'

import {isAuthenticated} from "~hoc";
import {IFetchPageBySlug} from "~interfaces";
import {getDynamicComponents} from "~utils";
import Script from "next/script";

const DynamicWrapper = (data: IFetchPageBySlug) => {
  const dynamicComponents = getDynamicComponents(data)
  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={process.env.NEXT_PUBLIC_ONE_TRUST_DOMAIN_DATA}
        strategy="beforeInteractive"
      />
      <Script
        id="OptInWrapperId"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { }`
        }}
      />
      {dynamicComponents}
    </>
  )
}

export default isAuthenticated(DynamicWrapper)