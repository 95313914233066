export const isValidFileVersion = (version: string) => {
  if (!version) {
    return false
  }

  if (!version.startsWith('v')) {
    return false
  }

  const versionNumber = version.substring(1, version.length)

  if (versionNumber.length !== 3) {
    return false
  }

  return !Number.isNaN(versionNumber)
}