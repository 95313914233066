import {CognitoIdentityProviderClient, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";

import {CLIENT_ID} from "~constants";
import {IConfirmPassword} from "~interfaces";
import {getSecretHash} from "~utils";

export const confirmPassword = async ({ Username, VerificationCode, Password }: IConfirmPassword) => {
  const client = new CognitoIdentityProviderClient({ region: 'us-east-1' })

  try {
    const secretHash = getSecretHash(Username)

    const input = {
      ClientId: CLIENT_ID,
      SecretHash: secretHash,
      Username,
      ConfirmationCode: VerificationCode,
      Password
    }

    const command = new ConfirmForgotPasswordCommand(input)

    return await client.send(command)
  } catch (error) {
    return error
  }
}