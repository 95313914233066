import {Dispatch, SyntheticEvent} from "react";

import {FORGOT_PASSWORD_ACTIONS} from "~enums";
import {IPreviewForgotPasswordForm} from "~interfaces";
import {confirmPassword} from "~services";

export const handleConfirmPassword = async (event: SyntheticEvent<IPreviewForgotPasswordForm>, dispatch: Dispatch<any>, email: string) => {
  const { newPassword, verificationCode } = event.currentTarget.elements

  try {
    const confirmRes = await confirmPassword({
      Username: email,
      Password: newPassword.value,
      VerificationCode: verificationCode.value
    })

    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.CONFIRM
    })

    return confirmRes
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      cognitoError: error
    })
  }
}
