import { useState } from 'react'
import styles from './index.module.scss'
import { HandShakeIcon, Heading, Modal, Paragraph } from '~elements'

export const TermsAndConditionsModal = () => {
  const [isAgreed, setIsAgreed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed)
    setErrorMessage('')
  }

  const handleConfirm = () => {
    if (isAgreed) {
      console.log('Redirecting to VPL...')
    } else {
      setErrorMessage('Please accept the Terms & Conditions to proceed.')
    }
  }

  const textContent = {
    html: `All imagery generated by this platform is digitally watermarked, and is the sole property of Sphere Entertainment, subject to the Terms of
          Service and may not be downloaded, captured, or used for any other purpose.`
  }

  return (
    <Modal isOpen={true} handleModalClose={() => {}} showCloseButton={false}>
      <div className={styles['terms-content']}>
        <HandShakeIcon />
        <Heading level={4}>Terms & Conditions</Heading>
        <Paragraph fontSize='md' text={textContent} justify='center' className={styles['normal-text']}/>
        <div className={`${styles['agreement-container']} ${errorMessage ? styles['error'] : ''}`}>
          <div className={styles['custom-checkbox']}>
            <input type="checkbox" checked={isAgreed} onChange={handleAgreementChange} className={styles.checkbox} id="termsCheckbox" />
            <label htmlFor="termsCheckbox">I agree</label>
          </div>
        </div>
        {errorMessage && <p className={styles['error-message']}>{errorMessage}</p>}
        <button onClick={handleConfirm} className={styles['confirm-button']}>
          Confirm
        </button>
      </div>
    </Modal>
  )
}