import {Dispatch} from "react";
import {InitPasswordRequest} from "~services";
import {FORGOT_PASSWORD_ACTIONS, FORGOT_PASSWORD_BTN_COLOR} from "~enums";

export const handleResendVerificationCode = async (email: string, dispatch: Dispatch<any>) => {
  try {
    const result = await InitPasswordRequest({ Username: email })

    if (result.status === 'Success') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.RESEND_CODE,
        payload: {
          email
        }
      })
    } else if (result.status === 'Error' && result.name !== 'LimitExceededException') {
      throw new Error('Failed to send verification code')
    } else if (result.status === 'Error' && result.name === 'LimitExceededException') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.RESEND_CODE,
        payload: {
          email,
          heading: {
            level: 1,
            title: 'You’re at max limit!',
            margin: '0 0 8px 0'
          },
          paragraph: {
            fontSize: '16px',
            justify: 'left',
            text: {
              html: `You have reached the maximum number of verification code requests. Please try again later.`
            }
          },
          submitButton: {
            text: 'Back to login',
            color: FORGOT_PASSWORD_BTN_COLOR.WHITE
          },
          fields: [],
          resendCode: false,
          resendCodeKicker: false,
          passwordConfirmed: true
        }
      })
    }
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      cognitoError: error
    })
  }
}