import cx from 'classnames'
import styles from '../DropdownThumbSelector/index.module.scss'
import Image from 'next/image'
import {useCallback} from 'react'
import {IToDThumbnailDetails, IVPThumbnailDetails} from '~interfaces'
import {EXOSPHERE_THUMB_ICON} from '~enums'
import {
  NorthEastIcon,
  NorthIcon,
  NorthWestIcon,
  EastIcon,
  SouthWestIcon,
  SouthEastIcon,
  SouthIcon,
  TopIcon,
  WestIcon
} from "../Icons";

export interface DropdownThumbnailProps {
  item: IToDThumbnailDetails | IVPThumbnailDetails
  handleItemClick: (item: IToDThumbnailDetails | IVPThumbnailDetails) => void
  selectedItem?: IToDThumbnailDetails | IVPThumbnailDetails
}

export const DropdownThumbnail = ({item, selectedItem, handleItemClick}: DropdownThumbnailProps) => {
  const getThumbnailClassNames = (item: any) => {
    return cx([styles['thumbnail'], selectedItem?.unreal_guid === item.unreal_guid ? styles['selected'] : ''])
  }

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const {key} = event
      if (key === ' ' || key === 'Spacebar') {
        handleItemClick(item)
      }
    },
    [handleItemClick, item]
  )

  const getIcon = (iconKey: string) => {
    switch (iconKey) {
      case EXOSPHERE_THUMB_ICON.NORTH_EAST:
        return <NorthEastIcon/>
      case EXOSPHERE_THUMB_ICON.NORTH:
        return <NorthIcon/>
      case EXOSPHERE_THUMB_ICON.NORTH_WEST:
        return <NorthWestIcon/>
      case EXOSPHERE_THUMB_ICON.WEST:
        return <WestIcon/>
      case EXOSPHERE_THUMB_ICON.TOP:
        return <TopIcon/>
      case EXOSPHERE_THUMB_ICON.EAST:
        return <EastIcon/>
      case EXOSPHERE_THUMB_ICON.SOUTH_WEST:
        return <SouthWestIcon/>
      case EXOSPHERE_THUMB_ICON.SOUTH:
        return <SouthIcon/>
      case EXOSPHERE_THUMB_ICON.SOUTH_EAST:
        return <SouthEastIcon/>
      default:
        return <></>
    }
  }

  return (
    <div role="menuItem" className={getThumbnailClassNames(item)} onClick={() => handleItemClick(item)} tabIndex={0}
         onKeyDown={handleKeyDown}>
      {item.thumbnail_url ? (
        <Image id={item.unreal_guid} src={item.thumbnail_url} alt={`${item.name} Thumbnail`} width="160" height="100"
               priority/>
      ) : null}
      <div className={styles['thumbnail-label']}>
        {'icon_key' in item ? getIcon(item.icon_key) : null}
        <span>{item.name}</span>
      </div>
    </div>
  )
}
