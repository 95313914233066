import styles from './index.module.scss'
import { ResultModal, Heading, Paragraph } from '~elements'
import { RESULT_MODAL_TYPE } from '~enums'

export const AccessDeniedModal = () => {

    const textContent = {
        html: 'Sorry, you don’t have access. <br /> Please contact your Sphere point of contact for help.'
    }
    
    return (
        <ResultModal
            isOpen={true}
            handleModalClose={() => { }}
            type={RESULT_MODAL_TYPE.ACCESS_DENIED}
            showActionButtons={false}
            showCloseOutButton={false}
        >
            <div className={styles['access-denied-wrapper']}>
                <Heading level={4} className={styles['heading']}>Access denied</Heading>
                <Paragraph
                    fontSize='md'
                    justify='center'
                    text={textContent}
                    className={styles['paragraph-container']}
                />
            </div>
        </ResultModal>
    )
}