import { IVPThumbnailDetails } from '~interfaces'

export const getSelectedVP = (selectedVantagePointId: string, data: IVPThumbnailDetails[]) => {
  for (let i = 0; i < data.length; i++) {
    const vantagePoint = data[i]
    if (vantagePoint.unreal_guid === selectedVantagePointId) {
      return vantagePoint
    }
  }
}
