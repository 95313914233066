/**
 * Executes callback if clicked outside referenced dom element
 * @param {RefObject<HTMLElement>} ref
 * @param {*} callback - function used to change state
 */
import {RefObject, useEffect} from "react";

export const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void) => {
  const handleClick = (e: { target: EventTarget | null }) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  })
}