import {ISetNewPassword} from "~interfaces";

export const setNewPassword = async ({ username, password, challengeName = 'NEW_PASSWORD_REQUIRED', session_code, name }: ISetNewPassword) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth-challenge`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        session: session_code,
        challengeName,
        name
      }),
      credentials: 'include'
    })

    return await result.json()
  } catch (e) {
    throw new Error('Unable to login user.')
  }
}
