import { DeleteFileIcon } from '~elements'
import {IDeleteFileProps} from "~interfaces";

export const DeleteFile = ({ openDeleteModal, closeDropdownMenu }: IDeleteFileProps) => {
  const handleDeleteButtonClick = () => {
    closeDropdownMenu()
    openDeleteModal(true)
  }

  return (
    <button onClick={() => handleDeleteButtonClick()} id="delete-button">
      <DeleteFileIcon />
      delete
    </button>
  )
}