import { createHmac } from 'crypto'

export function getSecretHash(Username: string) {
  const CLIENT_ID = `${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}`
  const CLIENT_SECRET = `${process.env.NEXT_PUBLIC_COGNITO_CLIENT_SECRET}`

  return createHmac('SHA256', CLIENT_SECRET)
    .update(Username + CLIENT_ID)
    .digest('base64')
}
