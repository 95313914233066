import { useRouter } from 'next/navigation'
import styles from './index.module.scss'
import { LanguageFillIcon } from '~elements'
import { usePreviewStore } from '~store'
import { CreatePreviewURL } from '~services'
import {IFileRecord} from "~interfaces";
import {Dispatch, MouseEvent, SetStateAction} from "react";

interface IPreviewFileProps {
  record: IFileRecord
  displayError: Dispatch<SetStateAction<string | null>>
}

export const PreviewSelectedFile = ({ record, displayError }: IPreviewFileProps) => {
  const router = useRouter()
  const { setSelectedVideo } = usePreviewStore()

  const handlePreviewClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    // Get preSigned url and then send user to the preview link.
    try {
      const preSignedUrl = await CreatePreviewURL(record.title)

      if (preSignedUrl) {
        setSelectedVideo({ url: preSignedUrl, fileName: record.title })
        router.push(`${process.env.NEXT_PUBLIC_DOMAIN_URL}video?id=${record.id}`)
      }
    } catch (error) {
      console.error(error)
      displayError(`Failed to preview file ${record.title}`)
    }
  }

  return (
    <button className={styles['preview-button']} onClick={handlePreviewClick} data-testid="preview-button">
      <LanguageFillIcon />
      Preview
    </button>
  )
}