import { IForgotPasswordReducerAction, IForgotPasswordReducerState } from '~interfaces'
import {FORGOT_PASSWORD_ACTIONS, FORGOT_PASSWORD_BTN_COLOR, VALIDATION_ACTIONS} from '~enums'

export const forgotPasswordReducer = (state: IForgotPasswordReducerState, action: IForgotPasswordReducerAction) => {
  switch (action.type) {
    case FORGOT_PASSWORD_ACTIONS.CONFIRM: {
      return {
        ...state,
        heading: {
          ...state.heading,
          title: 'Password reset success!'
        },
        paragraph: {
          ...state.paragraph,
          text: { html: `Password reset successful! Please click below to return to login.` }
        },
        fields: state.fields.map(field => ({ ...field, hidden: true })),
        passwordConfirmed: true,
        submitButton: {
          text: 'Back to login',
          color: FORGOT_PASSWORD_BTN_COLOR.WHITE
        },
        verificationCode: false,
        resendCode: false
      }
    }
    case FORGOT_PASSWORD_ACTIONS.INIT: {
      return {
        ...state,
        fields: state.fields.map(field => ({ ...field, hidden: !field.hidden })),
        heading: {
          ...state.heading,
          title: 'Please verify!'
        },
        paragraph: {
          ...state.paragraph,
          text: {
            html: `A verification code was sent to <strong data-type="forgot-password-email">${action.payload.email}</strong>. Please enter below with a new password.`
          }
        },
        submitButton: {
          text: 'Continue',
          color: FORGOT_PASSWORD_BTN_COLOR.TURQUOISE
        },
        verificationCodeSent: true,
        backToSignIn: false,
        resendCode: true,
        ...action.payload
      }
    }

    case FORGOT_PASSWORD_ACTIONS.RESEND_CODE: {
      return {
        ...state,
        heading: {
          ...state.heading,
          title: 'Please verify!'
        },
        paragraph: {
          ...state.paragraph,
          text: {
            html: `A verification code was sent to <strong data-type="forgot-password-email">${action.payload.email}</strong>. Please enter below with a new password.`
          }
        },
        submitButton: {
          text: 'Continue',
          color: FORGOT_PASSWORD_BTN_COLOR.TURQUOISE
        },
        verificationCodeSent: true,
        backToSignIn: false,
        resendCode: true,
        resendCodeKicker: {
          text: 'We have sent you another code.',
          fontSize: '16px',
          fontWeight: '600',
          color: '#c9a045'
        },
        ...action.payload
      }
    }

    case VALIDATION_ACTIONS.INVALID_INPUT: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state
    }
  }
}