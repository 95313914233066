import {PASSWORD_REGEX_EXPRESSION} from "~constants";
import {FORGOT_FIELD_KEYS} from "~enums";

export const LoginForm = {
  componentName: 'LoginForm',
  id: '1',
  fields: [
    {
      type: 'email',
      id: 'email',
      placeholder: 'Email *',
      label: 'Email *',
      required: true,
      validation: {
        message: 'This email is invalid!',
        regex: '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'
      }
    },
    {
      type: 'password',
      id: 'password',
      placeholder: 'Password *',
      label: 'Password *',
      required: true,
      validation: {
        message: 'This password is invalid!',
        regex: PASSWORD_REGEX_EXPRESSION
      }
    },
    {
      type: 'password',
      id: FORGOT_FIELD_KEYS.NEW_PASSWORD,
      hidden: true,
      placeholder: 'New Password',
      label: 'New Password',
      required: true,
      // Requires an uppercase, lowercase, special character and to be at least 8 characters long. It is pulled from OWASP and what cognito enforces.
      validation: {
        message: 'A valid password contains an uppercase, lowercase or special character and needs to be at least 8 characters long.',
        regex: PASSWORD_REGEX_EXPRESSION
      }
    },
    {
      type: 'password',
      id: FORGOT_FIELD_KEYS.CONFIRM_PASSWORD,
      hidden: true,
      placeholder: 'Confirm Password',
      label: 'Confirm Password',
      required: true,
      // Requires an uppercase, lowercase, special character and to be at least 8 characters long. It is pulled from OWASP and what cognito enforces.
      validation: {
        message: "Password does't match!",
        regex: null
      }
    }
  ],
  formType: 'login',
  heading: {
    level: 1,
    title: 'Exosphere Content Preview'
  },
  kicker: {
    text: 'Welcome to',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '2.4px'
  },
  rememberMe: {
    forgotPassword: {
      link: '/forgot',
      text: 'Forgot password?'
    }
  },
  submitButton: {
    text: 'Sign In'
  },
  backToSignIn: false
}