import { pages } from "~data";
import {IFetchPageBySlug, IFormBlock, IHeaderBlock} from "~interfaces";

export const fetchPageBySlug = (slug: string): IFetchPageBySlug => {
  const page = pages.find(page => page.slug === slug)

  if (!page) {
    throw Error(`Please check on project data, there is a missing value for ${slug}`)
  }

  return page
}