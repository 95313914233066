import { IToDThumbnailDetails, IThumbnailDetails } from '~interfaces'
import { LOCAL_STORAGE } from '~enums'

interface ITimeOfDayData {
  data: Array<IToDThumbnailDetails>
}

export const GetTimesOfDay = async (): Promise<ITimeOfDayData> => {
  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData) {
      throw new Error('Session data is missing.')
    }

    const { id } = JSON.parse(userSessionData)

    headers.append('Authorization', `Bearer ${id}`)
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/times-of-day`, {
      method: 'GET',
      headers
    })
    const timeOfDayData = await result.json()
    const timeOfDayDataWithImageUrls = timeOfDayData.map((timeOfDay: IThumbnailDetails) => {
      const thumbnailUrl = `https://${process.env.NEXT_PUBLIC_EXO_CLOUDFRONT_DIST}/${timeOfDay.thumbnail_image_key}`
      return { ...timeOfDay, thumbnail_url: thumbnailUrl }
    })
    return { data: timeOfDayDataWithImageUrls }
  } catch (e) {
    throw new Error('Unable to get time of day data.')
  }
}
