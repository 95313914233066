import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import {PasswordHintErrorIcon, PasswordHintSuccessIcon} from "~elements";
interface IPasswordRequirements {
  text: string
}

export const PasswordRequirements = ({ text }: IPasswordRequirements) => {
  const getIcon = (status: boolean) => {
    if (!status) {
      return <PasswordHintErrorIcon />
    }

    return <PasswordHintSuccessIcon />
  }

  const requirements = [
    {
      message: 'Include at least 1 uppercase letter',
      status: false,
      id: 'UPPER'
    },
    {
      message: 'Include at least 1 lowercase letter',
      status: false,
      id: 'LOWER'
    },
    {
      message: 'Include a number',
      status: false,
      id: 'NUMBER'
    },
    {
      message: `Include a special character: ^$*.[]{}()?-"!@# %& / \\ ,><':;|_~\`+ =`,
      status: false,
      id: 'SPECIAL'
    },
    {
      message: 'Between 8-64 letters long',
      status: false,
      id: 'LENGTH'
    }
  ]

  const [requirementsStatus, setReqStatus] = useState(requirements)

  useEffect(() => {
    let newPassReq = []

    const upperCaseLetters = /[A-Z]/g
    const upperCase = requirementsStatus.find(pswdRequirement => pswdRequirement.id === 'UPPER')!
    newPassReq.push({ ...upperCase, status: !!text.match(upperCaseLetters) })

    const lowerCaseLetters = /[a-z]/g
    const lowerCase = requirementsStatus.find(pswdRequirement => pswdRequirement.id === 'LOWER')!
    newPassReq.push({ ...lowerCase, status: !!text.match(lowerCaseLetters) })

    const numbers = /[0-9]/g
    const number = requirementsStatus.find(pswdRequirement => pswdRequirement.id === 'NUMBER')!
    newPassReq.push({ ...number, status: !!text.match(numbers) })

    const specialChar = /(?=.*[_?/<>"'|!@#$%^&*,{}\[\].\`():;+=~\\-])/g
    const special = requirementsStatus.find(pswdRequirement => pswdRequirement.id === 'SPECIAL')!
    newPassReq.push({ ...special, status: !!text.match(specialChar) })

    const passLen = requirementsStatus.find(pswdRequirement => pswdRequirement.id === 'LENGTH')!
    newPassReq.push({ ...passLen, status: !!(text && text.length >= 8 && text.length <= 64) })

    setReqStatus([...newPassReq])
  }, [text])

  return (
    <div className={styles['requirements-container']}>
      {requirementsStatus.map(({ status, message, id }) => {
        return (
          <div key={id} className={styles['requirements-row']}>
            {getIcon(status)}
            <span data-status={status}>{message}</span>
          </div>
        )
      })}
    </div>
  )
}
