export const AccessDeniedIcon = () => {
  return (
      <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="53" height="53" rx="26.5" stroke="#6D0000" />
          <mask id="mask0_7482_13837" maskUnits="userSpaceOnUse" x="15" y="15" width="24" height="24">
              <rect x="15" y="15" width="24" height="24" fill="#FF4444" />
          </mask>
          <g mask="url(#mask0_7482_13837)">
              <path d="M27 35.9626C24.991 35.3665 23.3221 34.1495 21.9933 32.3116C20.6644 30.4738 20 28.4036 20 26.1011V20.6934L27 18.0781L34 20.6934V26.1011C34 28.4036 33.3356 30.4738 32.0068 32.3116C30.6779 34.1495 29.009 35.3665 27 35.9626ZM27 34.9011C28.7333 34.3511 30.1667 33.2511 31.3 31.6011C32.4333 29.9511 33 28.1178 33 26.1011V21.3761L27 19.1454L21 21.3761V26.1011C21 28.1178 21.5667 29.9511 22.7 31.6011C23.8333 33.2511 25.2667 34.3511 27 34.9011ZM25.0385 30.6549H28.9615C29.1903 30.6549 29.3822 30.5775 29.537 30.4229C29.6918 30.268 29.7693 30.0762 29.7693 29.8474V26.9241C29.7693 26.6953 29.6918 26.5035 29.537 26.3486C29.3822 26.194 29.1903 26.1166 28.9615 26.1166H28.7885V25.1166C28.7885 24.6178 28.6183 24.1982 28.278 23.8579C27.9375 23.5175 27.5179 23.3474 27.0192 23.3474C26.5206 23.3474 26.101 23.5175 25.7605 23.8579C25.4202 24.1982 25.25 24.6178 25.25 25.1166V26.1166H25.0385C24.8097 26.1166 24.6178 26.194 24.463 26.3486C24.3082 26.5035 24.2308 26.6953 24.2308 26.9241V29.8474C24.2308 30.0762 24.3082 30.268 24.463 30.4229C24.6178 30.5775 24.8097 30.6549 25.0385 30.6549ZM26.0192 26.1166V25.1166C26.0192 24.8333 26.1151 24.5958 26.3067 24.4041C26.4984 24.2125 26.7359 24.1166 27.0192 24.1166C27.3026 24.1166 27.5401 24.2125 27.7318 24.4041C27.9234 24.5958 28.0193 24.8333 28.0193 25.1166V26.1166H26.0192Z" fill="#FF4444" />
          </g>
      </svg>
  )
}