import { Header } from './Header'
import { LoginForm } from './Forms/Login'
import { ForgotPasswordForm } from './Forms/ForgotPassword'

export const pages = [
  {
    title: 'Exo Preview | Home',
    slug: 'list',
    componentName: 'Page',
    blocks: [
      { ...Header, isPreview: true },
      {
        componentName: 'Dashboard',
        id: '19e29012e',
        heading: {
          levelDisplay: 1,
          margin: 0,

          level: 1,
          title: 'Exosphere Content Preview'
        },
        kicker: {
          text: 'Preview Exosphere content from key vantage points',
          fontSize: '16px'
        },
        paragraph: {
          justify: 'center',
          text: {
            html: '<a href="#">Click to upload</a> or drag and drop.'
          }
        }
      }
    ],
    seo: {
      ogImage: {
        url: 'https://media.graphassets.com/cod4u8X5RauPh9FZbxs0'
      },
      seoTitle: 'Sphere | Preview',
      shouldSearchEnginesFollowLinks: false,
      allowSearchEnginesToShowThisPage: false,
      metaDescription: 'A list of all items you are able to see.'
    },
    checkAuthentication: true
  },
  {
    title: 'Exo Preview | Login',
    slug: 'login',
    componentName: 'Page',
    blocks: [{ ...Header, simple: true, isPreview: true }, LoginForm],
    seo: {
      ogImage: {
        url: 'https://media.graphassets.com/cod4u8X5RauPh9FZbxs0'
      },
      seoTitle: 'Sphere | Preview',
      shouldSearchEnginesFollowLinks: false,
      allowSearchEnginesToShowThisPage: false,
      metaDescription: 'A page used to preview content.'
    },
    checkAuthentication: true
  },
  {
    title: 'Exo Preview | Video Stream',
    slug: 'video',
    componentName: 'Page',
    blocks: [
      {
        ...Header,
        simple: true,
        videoPlayer: true,
        isPreview: true,
        logo: {
          ...Header.logo,
          altUrl: '/assets/alt-logo.png'
        }
      },
      {
        componentName: 'RenderClient'
      }
    ],
    checkAuthentication: true
  },
  {
    title: 'Exo Preview | Forgot',
    slug: 'forgot',
    componentName: 'Page',
    blocks: [{ ...Header, simple: true, isPreview: true }, ForgotPasswordForm],
    checkAuthentication: false
  }
]
