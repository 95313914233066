import {Dispatch} from "react";

import {VALIDATION_ACTIONS} from "~enums";
import {IField} from "~interfaces";

export const handleFailedLogin = (fields: Array<IField>, dispatch: Dispatch<any>, errorMessage: string) => {
  const invalidFields: Record<string, unknown> = {}
  fields.forEach(field => {
    if (!field.hidden && field.validation) {
      if (field.type === 'password' || 'email') {
        invalidFields[field.id] = { message: errorMessage, field }
      }
    }
  })

  // If our invalidFields has value dispatch login error.
  dispatch({
    type: VALIDATION_ACTIONS.INVALID_INPUT,
    payload: { invalidFields: { ...invalidFields } }
  })

  return invalidFields
}