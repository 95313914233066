interface ConditionalWrapperProps {
  children: JSX.Element | JSX.Element[]
  condition: boolean
  wrapper: (children: JSX.Element | JSX.Element[]) => JSX.Element | null
}

export const ConditionalWrapper = ({ children, condition, wrapper }: ConditionalWrapperProps): JSX.Element => {
  const wrappedChildren = condition ? wrapper(children) : children
  return <>{wrappedChildren}</>
}
