import {Dispatch, SyntheticEvent} from "react";
import {IPreviewLoginForm} from "~interfaces";
import {LOGIN_ACTIONS} from "~enums";
import {setNewPassword} from "~services";

export const handleSetNewPassword = async (
  event: SyntheticEvent<IPreviewLoginForm>,
  dispatch: Dispatch<any>,
  session_code: string,
  email: string,
  userAttributes: any
) => {
  const { newPassword, confirmPassword } = event.currentTarget.elements

  try {
    const name = userAttributes && userAttributes.name ? userAttributes.name : ''
    const setNewRes = await setNewPassword({ username: email, password: newPassword.value, session_code, name })

    dispatch({
      type: LOGIN_ACTIONS.SET_NEW_CONFIRM
    })

    return ''
  } catch (error) {
    console.log(error)
  }
}
