import { getCognitoUserSession } from 'utils/user/getCognitoUserSession'
import { LOCAL_STORAGE } from '~enums'

export const GetVideos = async () => {
  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData) {
      throw new Error('Session data is missing.')
    }

    const { id } = JSON.parse(userSessionData)

    headers.append('Authorization', `Bearer ${id}`)
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/videos`, {
      method: 'GET',
      headers
    })
    return { data: await result.json() }
  } catch (e) {
    throw new Error('Unable to get videos for user.')
  }
}
