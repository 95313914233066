import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import { useOutsideClick } from '~hooks'

export interface IPopoverProps {
  children: JSX.Element
  popoverCTA: JSX.Element
  isConfirmationModalOpen?: boolean
  bodyWidth?: string
  bodyHeight?: string
}

export const Popover = ({ popoverCTA, children, isConfirmationModalOpen = false, bodyWidth = '225px', bodyHeight = '126px' }: IPopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isConfirmationModalOpen) {
      setIsOpen(false)
    }
  }, [isConfirmationModalOpen])

  useOutsideClick(popoverRef, () => {
    if (!isConfirmationModalOpen) {
      setIsOpen(false)
    }
  })

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Escape') {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }

  return (
    <div className={styles['popover-wrapper']} ref={popoverRef}>
      <div onClick={handleClick}>{popoverCTA}</div>
      {isOpen ? (
        <div className={styles['popover-body']} style={{ width: bodyWidth, height: bodyHeight }}>
          {children}
        </div>
      ) : null}
    </div>
  )
}