import { useEffect } from 'react'
import GetCurrentTimeOfDayCommand from 'render-streaming-client/build/command/GetCurrentTimeOfDayCommand'
import SetTimeOfDayCommand from 'render-streaming-client/build/command/SetTimeOfDayCommand'
import { DropdownThumbSelector, SunnyIcon } from '~elements'
import { usePreviewStore } from 'store'
import { ICurrentTimeOfDayData, IToDThumbnailDetails } from '~interfaces'

export const TimeOfDay = () => {
  const { renderingManager, allTimesOfDay, selectedTimeOfDay, setSelectedTimeOfDay } = usePreviewStore()

  useEffect(() => {
    return () => {
      setSelectedTimeOfDay(undefined)
    }
  }, [])

  useEffect(() => {
    // We only fetch the current time of day when necessary
    const fetchData = async () => {
      if (renderingManager && allTimesOfDay?.length && !selectedTimeOfDay) {
        await getSelectedTimeOfDay()
      }
    }
    fetchData().catch(error => console.error(error))
  }, [renderingManager, allTimesOfDay])

  const getSelectedTimeOfDay = async () => {
    const getCurrent = new GetCurrentTimeOfDayCommand()
    renderingManager?.onCommandResponse(getCurrent, data => {
      try {
        const parsedResponse: ICurrentTimeOfDayData = JSON.parse(data)
        const selected = allTimesOfDay.find(timeOfDay => timeOfDay.unreal_guid === parsedResponse.Payload.lightingScenarioInfo.scenarioId)
        if (selected) {
          setSelectedTimeOfDay(selected)
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }

        throw new Error('Unknown error in fetching current time of day.')
      }
    })

    renderingManager?.executeCommand(getCurrent)
  }

  const applyTimeOfDay = (timeOfDay: IToDThumbnailDetails) => {
    if (!renderingManager) {
      return
    }

    try {
      const setTimeOfDay = new SetTimeOfDayCommand(timeOfDay.unreal_guid)

      // Please use for debugging purposes.
      // renderingManager.onCommandResponse(setTimeOfDay, data => {
      //   console.log(data)
      // })
      renderingManager.executeCommand(setTimeOfDay)
      setSelectedTimeOfDay(timeOfDay)
      console.log(`Attempt to apply time of day ${timeOfDay.unreal_guid}`)
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }

      throw new Error('Unknown error in applying time of day.')
    }
  }

  return renderingManager && allTimesOfDay?.length && selectedTimeOfDay ? (
    <DropdownThumbSelector
      onChange={applyTimeOfDay}
      groups={[{ group_name: 'default', id: '1', vantage_points: [...allTimesOfDay] }]}
      selectedItem={selectedTimeOfDay}
      Icon={SunnyIcon}
      type={'TIME-OF-DAY'}
    />
  ) : null
}
