import {Dispatch, SyntheticEvent} from "react";
import {IField, IPreviewForgotPasswordForm, IPreviewLoginForm} from "~interfaces";
import {FORGOT_FIELD_KEYS, VALIDATION_ACTIONS} from "~enums";

type InvalidField = {
  [key: string]: {
    message: string
    field: IField
  }
}

export const handleInputValidation = (
  event: SyntheticEvent<IPreviewForgotPasswordForm | IPreviewLoginForm>,
  fields: Array<IField>,
  dispatch: Dispatch<any>
) => {
  const invalidFields: InvalidField = {}
  fields.forEach(field => {
    if (!field.hidden && field.validation) {
      //@ts-ignore
      const currentElement = event.currentTarget.elements[field.id]
      const { regex, message } = field.validation
      const currentValue = currentElement.value
      const regexTester = new RegExp(regex)
      if (field.id === FORGOT_FIELD_KEYS.CONFIRM_PASSWORD && currentValue !== '') {
        const newPassword = event.currentTarget.elements[FORGOT_FIELD_KEYS.NEW_PASSWORD]

        if (newPassword.value !== currentValue) {
          invalidFields[field.id] = { message, field }
        }
        if (newPassword.value === '') {
          const requiredMessage = 'Password doesn’t match!'
          invalidFields[field.id] = { message: requiredMessage, field }
        }
      } else if (field.required && currentValue === '') {
        const requiredMessage = 'This field is required!'
        invalidFields[field.id] = { message: requiredMessage, field }
      } else if (!regexTester.test(currentValue)) {
        invalidFields[field.id] = { message, field }
      }
    }
  })

  // If our invalidFields has value dispatch invalidation error.
  dispatch({
    type: VALIDATION_ACTIONS.INVALID_INPUT,
    payload: { invalidFields: { ...invalidFields }, event }
  })

  return invalidFields
}